import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import SettingMer from '@/utils/settingMer'
import { getToken } from "@/utils/auth";
import router from '@/router'


const service = axios.create({
  baseURL: SettingMer.apiBaseURL,
  timeout: 120000 // 过期时间
})

// request interceptor
service.interceptors.request.use(
  config => {
    // 发送请求之前做的
    if (/get/i.test(config.method)) {
      config.params = config.params || {}
      config.params.temp = Date.parse(new Date()) / 1000
    }
    config.headers.xtoken = getToken() || '';
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
    if (response.headers["content-disposition"] != undefined) {
      response.headers['responseType'] = 'arraybuffer'
      return response.data
    }
    if (res.code === 401 || res.code === '900004') {
      // location.reload()
      router.push("/login");
    }
    if (res.code !== 200 && res.code !== '000000' && res.code !== 401) {
      Message({
        message: res.msg || 'Error',
        type: 'error',
        duration: 5 * 1000
      })
      return Promise.reject()
    } else {
      return res.data
    }
  },
  error => {
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service